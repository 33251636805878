<template>
  <section class="about py-5">
    <div class="container" v-if="data">
      <h2 data-aos="fade-up">Sobre mim</h2>
      <div class="row me mt-5">
        <div class="img col-lg-3 col-md-5 mx-auto">
          <img src="imgs/drannibal.jpg" alt="Dr. Annibal Amorim Jr" />
          <span>Dr. Annibal Amorim Jr</span>
          <p>CRM: 52.89061-8</p>
        </div>
        <div class="col-lg-9 info" data-aos="fade-down">
          <div class="wrapper ml-md-4 ml-0">
            <div class="item">
              <h4>Formação</h4>
              <p>{{ data.sobre.formacao }}</p>
            </div>
            <div
              class="item mt-4"
              v-for="info in data.sobre.informacoes"
              :key="info.titulo"
            >
              <h4>{{ info.titulo }}</h4>
              <ul class="list-unstyled">
                <li v-for="(list, index) in info.items" :key="index">
                  <i class="bi bi-check mx-2 h4"></i>{{ list }}
                </li>
              </ul>
            </div>
            <a
              href="https://www.doctoralia.com.br/annibal-amorim-junior"
              target="_blank"
              class="btn more"
              >Saiba mais</a
            >
          </div>
        </div>
      </div>
    </div>

    <VueZap />
  </section>
</template>
<script>
import VueZap from "@/components/VueZap.vue";
import { mapState } from "vuex";
export default {
  name: "Sobre",
  computed: {
    ...mapState(["data"]),
  },
  components: {
    VueZap,
  },
};
</script>
