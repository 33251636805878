<template>
  <section class="slideHome">
    <div
      id="slide"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      v-if="data"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item"
          :class="{ active: slide.ativo }"
          v-for="slide in data.slides"
          :key="slide.titulo"
        >
          <img :src="slide.imgUrl" class="d-block w-100" :alt="slide.titulo" />
          <div class="carousel-caption">
            <h5>{{ slide.titulo }}</h5>
            <p>{{ slide.desc }}</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-target="#slide"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-target="#slide"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Proximo</span>
      </button>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "VueSlide",

  computed: {
    ...mapState(["data"]),
  },
};
</script>
