<template>
  <section class="cirurgias py-5" v-if="data">
    <div class="container">
      <h2 class="title" data-aos="fade-up">Especialidades</h2>
      <div class="row justify-content-center">
        <div
          class="col-lg-3"
          v-for="cirurgia in data.cirurgias"
          :key="cirurgia.titulo"
          data-aos="zoom-in"
        >
          <div class="wrapper item">
            <img :src="cirurgia.image" :alt="cirurgia.titulo" />
            <h4 class="title">{{ cirurgia.titulo }}</h4>
            <p class="desc lead">{{ cirurgia.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "VueCirurgias",
  computed: {
    ...mapState(["data"]),
  },
};
</script>
