<template>
  <footer class="footer">
    <div class="container" v-if="data">
      <div class="row footer-info">
        <div class="col-md-6 col-lg-3">
          <h4 class="title">PÁGINAS:</h4>
          <ul class="list-unstyled">
            <li v-for="rota in data.rotas" :key="rota.titulo">
              <router-link :to="{ name: rota.nomeView }" class="rota">{{
                rota.titulo
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-3">
          <h4 class="title">CONTATOS:</h4>
          <ul class="list-unstyled">
            <li>
              <a :href="`mailto:${data.contatos.email}`"
                ><i class="bi bi-envelope"></i>{{ data.contatos.email }}</a
              >
            </li>
            <li v-for="tel in data.contatos.tels" :key="tel">
              <a :href="`tel:+5521${tel}`">
                <i class="bi bi-telephone"></i>(21) {{ tel }}</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-3">
          <h4 class="title">LOCAIS:</h4>

          <ul class="list-unstyled">
            <li v-for="local in data.contatos.locais" :key="local.endereco">
              <a :href="local.mapa" target="_blank">
                <i class="bi bi-geo-alt"></i>{{ local.endereco }}
              </a>
            </li>
          </ul>
        </div>

        <div class="col-md-6 col-lg-3">
          <h4 class="title">REDES SOCIAIS:</h4>
          <ul class="list-unstyled">
            <li v-for="social in data.sociais" :key="social.titulo">
              <a :href="social.url" target="_blank">
                <i :class="social.logo"></i>{{ social.titulo }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="copy">
        © Copyright {{ year }} | Todos os direitos reservados.
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "VueFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState(["data"]),
  },

  methods: {
    top() {
      console.log("teste");
      window.scrollTo(0, 0);
    },
  },
};
</script>
