<template>
  <div>
    <VueSlide />
    <VueCirurgias />
    <VueZap />
  </div>
</template>
<script>
import VueSlide from "@/components/VueSlide.vue";
import VueCirurgias from "@/components/VueCirurgias.vue";
import VueZap from "@/components/VueZap.vue";
export default {
  name: "Home",
  components: {
    VueSlide,
    VueCirurgias,
    VueZap,
  },
};
</script>
