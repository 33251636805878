<template>
  <header class="header py-2">
    <nav class="navbar navbar-expand-lg navbar-light container">
      <a class="navbar-brand" href="/">
        <img alt="logo" src="../assets/logo.png"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-if="data"
      >
        <ul class="navbar-nav ml-auto text-center">
          <li class="nav-item" v-for="rota in data.rotas" :key="rota.titulo">
            <router-link :to="{ name: rota.nomeView }" class="nav-link">{{
              rota.titulo
            }}</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "VueHeader",
  computed: {
    ...mapState(["data"]),
  },
};
</script>
