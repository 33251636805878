<template>
  <div class="about">
    <h1>Página Contato....</h1>
  </div>
</template>
<script>
export default {
  name: "Contato",
  components: {},
};
</script>
