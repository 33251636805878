<template>
  <div>
    <a
      href="https://api.whatsapp.com/send/?phone=5521990788953"
      target="_blank"
      class="WhatsappButton"
      ><span class="content"
        ><span class="title">Atendimento por WhatsApp?</span>
        <span class="phone">Clique aqui</span>
      </span>
      <div class="logo">
        <img src="/imgs/whatsapp.png" alt="logo do Whatsapp" /></div
    ></a>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "VueCirurgias",
  computed: {
    ...mapState(["data"]),
  },
};
</script>
<style lang="scss">
.WhatsappButton {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  top: 90%;
  right: 0;
  height: 80px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1000 !important;

  .content {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 20px;
    background-color: #fff;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .title {
      white-space: nowrap;
      color: #2c2d2d;
    }

    .phone {
      white-space: nowrap;
      font-weight: 700;
      color: #af1e03;
    }

    &.positionLeft {
      left: -250px;
    }
  }

  .logo {
    z-index: 2;
    position: relative;
    height: 100%;
    background-color: #02bb38;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      color: #fff;
      height: 50px;
      width: 50px;
      margin: 0 10px;
      background-repeat: no-repeat;
    }
  }

  &:hover {
    .content {
      left: -250px;
    }
  }
}
</style>
>
