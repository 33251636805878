import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    data: null,
  },
  mutations: {
    CHANGE_DATA(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    loadApi(context) {
      fetch("./data.json")
        .then((data) => data.json())
        .then((res) => {
          context.commit("CHANGE_DATA", res);
        });
    },
  },
  getters: {},
});
