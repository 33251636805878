<template>
  <div id="app">
    <VueHeader :data="data" />

    <main>
      <router-view />
    </main>

    <VueFooter :data="data" />
  </div>
</template>

<script>
import VueHeader from "@/components/VueHeader.vue";
import VueFooter from "@/components/VueFooter.vue";
import AOS from "aos";
import "../src/assets/style.css";

export default {
  components: {
    VueHeader,
    VueFooter,
  },
  data() {
    return {
      data: null,
    };
  },

  created() {
    this.$store.dispatch("loadApi");
  },
  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
  },
  methods: {
    routeLoaded() {
      AOS.init({
        once: true,
        duration: 600,
      });
    },
  },
  mounted() {
    this.$router.onReady(() => this.routeLoaded());
  },
};
</script>
